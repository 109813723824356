* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

footer {
    max-width: 100%;
    margin-top: 70px;
    padding: 8px;
    margin-bottom: 1px;
    background-color: #70727406;
    overflow: hidden;
    display: block;
    font-size: 15px !important;
    color: #707274;
    
}

.row-footer{
    display: flex;
    text-align: center;
}

.fa-globe,
.fa-phone,
.fa-pager,
.fa-envelope {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #9eb454;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: #707274;
}

footer .contact p {
    margin: 0;
    font-weight: bold;
}

.footer-p:hover {
  color:#9eb454;
}

footer .contact {
    margin: 0;
    font-weight:lighter;
}

.copyRight {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
    font-size:small;
    color: #707274;
    font-weight: bold;
}

/* ======================= FOR SMALL DEVICES  ======================= */
@media screen and (min-width: 320px) and (max-width: 844px) {
    .footer {
        margin-top: 30px;
        padding: 0;
    }

    .container-fluid {
        margin: 0;
        padding: 5px;
    }

    .row-footer {
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .footer-p {
        padding-left: 8px;
        font-size: x-small;
        color: #707274;
        font-weight: 500;
    }

    .footer-p b {
        font-weight: bold;
    }

    .address {
        font-size: x-small;
    }

    .fa-globe,
    .fa-phone,
    .fa-pager,
    .fa-envelope {
        font: small small small 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #9eb454;
    }

    .copyRight {
        font-size: xx-small;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .raadWeb {
        text-decoration: none;
        margin-left: 1px;
        color: #4d8bc9;
        font-weight: bold;
    }

    .raadLogo {
        display: none;
    }
}
/* ======================= FOR LARGE DEVICES  ======================= */
@media screen and (min-width:844px) and (max-width:1920px) {
    .footer {
        margin-top: 60px;
        margin-bottom: 20px;
        padding: 0;
    }

    .container-fluid {
        margin: 0;
        padding: 5px;
    }

    .row-footer {
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .footer-p {
        padding-left: 8px;
        font-size: x-small;
        color: #707274;
        font-weight: 500;
    }

    .footer-p b {
        font-weight: bold;
    }

    .address {
        font-size: x-small;
    }

    .fa-globe,
    .fa-phone,
    .fa-pager,
    .fa-envelope {
        font: small small small 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #9eb454;
    }

    .copyRight {
        font-size: xx-small;
        margin-top: 10px;
    }

    .raadWeb {
        text-decoration: none;
        margin-left: 5px;
        color: #4d8bc9;
        font-weight: bold;
    }

    .raadLogo {
        width: 3%;
    }
}