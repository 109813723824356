html, body, #root {  height: 100%}

.contact {
  height: min-content;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-image: url(../../assets/bgLogo.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.email-form {
  margin: auto;
  padding: 5px;
}

.contact-row {
  margin: 0;
  padding: 8px;
  margin-right: 50px;
  margin-left: 50px;
}

.get {
  margin: 0;
  margin-top: 10px;
  padding: 8px;
  line-height: 3;
}

h2 {
  color:#9eb454;
  font-weight: 400;
}

.bContact {
  color: #707274;
  font-weight: bold;
}

.gIcons{
  color: #707274;
  font-weight:500
}

.gIcons:hover {
  color: #9eb454;
}

.aTag {
  text-decoration: none;
}

#contForm {
  line-height: 0.5;
}

#exampleFormControlInput1 {
  border-radius: 50px;
  border-color: #9eb454;
}

#exampleFormControlTextarea1 {
  border-radius: 20px;
  border-color: #9eb454;
}

#submitBtn {
  margin-top: 20px;
  border-radius: 20px;
  border-color: #707274;
  color: #707274;
  width: 150px;
  background-color: #9eb454;
  font-weight: bold;
}

#submitBtn:hover {
  border-color: #9eb454;
  color: #9eb454;
  background-color: #707274;
}

#exampleFormControlTextarea1:hover, #exampleFormControlInput1:hover {
  border-color: #707274;
}

/* ======================= FOR SMALL DEVICES  ======================= */

@media screen and (min-width: 320px) and (max-width: 844px) {
 
  #cont-form {
    margin-bottom: 80px;
    padding: 8px;
  }

  .contact-row {
    margin: 0;
    padding: 5px;
  }

  .get {
    margin-top: 160px;
    margin-bottom: 10px;
    padding: 5px;
    width: 100%;
    line-height: 3;
  }

  #contForm {
    margin: 0;
    padding: 5px;
    width: 100%;
    line-height: 2;
  }

  #conCol {
    margin: 0;
    padding: 0;
  }
}
/* ======================= FOR LARGE DEVICES  ======================= */
@media screen and (min-width:844px) and (max-width:1920px) {
  #cont-form {
    margin-top: 50px;
    margin-bottom: 70px;
    padding: 8px;
  }
  .get {
    margin-top: 20px;
    margin-bottom: 50px;
    padding: 5px;
  }

  #contForm {
    margin: 0;
    padding: 5px;
    line-height: 1;
  }
}