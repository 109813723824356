.navbar{
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  width: 100%;
  margin: 0;
  padding: 5px;
  top: 0;
  background: rgb(255, 255, 255);
  display: block;
}

.container-fluid {
  margin: 0;
  padding: 0;
}

.pfLogo{
  margin: 0;
  padding: 0;
}

#logoImg{
  width: 270px;
  height: auto;
  margin: 0;
  padding: 0;
}

.navbar-collapse {
  justify-content: end;
}

.navbar-nav {
  margin-right: 60px;
}


.nav-link {
  font-size: medium;
  color: #707274;
}

.nav-link:hover {
  color: #9eb454;
}

.navbar-nav .nav-link.active {
  font-weight: bold;
  color: #9eb454;
}

/* ======================= FOR SMALL DEVICES  ======================= */
@media screen and (min-width: 320px) and (max-width: 844px) {
  #logoImg{
    margin: 0;
    padding: 0;
    width: 250px;
  }

  .navbar-toggler{
    color:#9eb454;
  }

  .navbar {
    position: -webkit-sticky;
    position: sticky;
    position:fixed;
    background-color: rgb(255, 255, 255);
  }

  .navbar-nav {
    text-align: center;
    margin: auto;
    background-color: rgba(255, 254, 254, 0.933);
  }
}

/* ==================== FOR SMALL DEVICES (Portrait) ====================
@media only screen and (min-width: 600px) {
  .navbar {
    position: absolute;
  }
}*/