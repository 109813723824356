/*==================== Welcome Page ====================*/
.welcome-page {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-image: url(../../assets/bgLogo.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.devider {
  color: #707274;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  width: 60%;
}

/*==================== Main Machine ====================*/
.machine-container{
  margin: 0;
  margin-top: 90px;
  padding: 10px;
  display: block;
  max-width: 100%;
}

.blowinFilm {
  display: flex;
  margin: 0;
  padding: 10px;
}

.machineImage {
  display: flex;
  margin: 0;
  padding: 10px;
}


#intro {
  margin-top:20px;
  padding: 10px;
  display: block;
}

.introHead {
  color:#9eb454;
}

.pIntro {
  margin-top: 20px;
  padding: 5px;
  margin-right: 0px;
  align-items: center;
  justify-content: center;
  color: #707274;
  display: flex;
  font-size: large;
  font-weight:400;
  line-height: 1.8;
}

/*==================== Recycle Machine ====================*/
.recycling-container{
  margin: 0;
  padding: 10px;
  display: block;
  max-width: 100%;
}

.recycle {
  display: flex;
  margin: 0;
  padding: 10px;
}

.rHead {
  color:#9eb454;
  margin-left: 130px;
}

.machineImg {
  display: flex;
  margin-right: 60px;
  padding: 10px;
  width: max-content;
  height: 20rem;
}

.rIntro {
  margin-top: 20px;
  margin-left: 130px;
  align-items: center;
  justify-content: center;
  color: #707274;
  display: flex;
  font-size: large;
  font-weight:400;
  line-height: 1.8;
}

/*==================== Products Cards ====================*/

.card-group{
    display: flex;
    margin-top: 60px;
    margin-right: 80px;
    margin-left: 80px;
    padding: 10px;
    max-width: 100%;
}

.card{
  margin: 10px;
  padding: 0;
  height: 100%;
}

.card-title {
  color:#9eb454;
}

#cardText{
  padding: 0;
  margin-top: 10px;
  margin-bottom: 18px;
}

.btn {
  border-color: #707274;
  color: #707274;
  background-color: #9eb454a9;
  font-weight: bold;
}

.btn:hover {
  border-color: #9eb454;
  color: #9eb454;
  font-weight: bold;
}

#btnGH {
  margin-top: 17px;
}
        
/* ======================= FOR SMALL DEVICES  ======================= */
@media screen and (min-width: 320px) and (max-width: 844px) {
  .machine-container {
    margin: 0;
    margin-top: 90px;
    padding: 8px;
    max-width: 100%;
  }

  .blowinFilm {
    display: flex;
    margin: 0;
    padding: 5px;
  }

  .recycling-container {
    margin: 0;
    padding: 8px;
    max-width: 100%;
  }

  .machineImage, 
  .recycle,
  .recycleImage,
  .machineImg {
    margin: 0;
    display: flex;
    max-width: 100%;
    height: auto;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }

  .machineImg {
    width: 800px;
    margin: 0;
    display: flex;
    height: auto;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }


  #intro {
    margin: 0;
    max-width: 100%;
    height: auto;
    padding: 5px;
  }

  .introHead,
  .rHead {
    font-size: x-large;
    font-weight: bold;
    margin-top: 30px;
    margin: 0;
    padding: 5px;
    color: #9eb454a9;
  }

  .pIntro,
  .rIntro {
    margin: 0;
    padding: 5px;
    font-size: large;
    line-height: 1.7;
    color: #707274;
  }

  .card-group {
    display: flex;
    margin-top: 50px;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
    padding: 8px;
    max-width: 100%;
  }

  #productsCard {
    margin: 5px;
    padding: 0;
  }

  .card-body {
    max-height: max-content;
  }

  .card-title {
    padding: 2px;
    margin: 0;
    color: #9eb454a9;
    font-size: small;
    font-weight: bolder;
  }

  #textPr,
  #textGh,
  #textCr {
    margin: 0;
    padding: 5px;
    line-height: 1.15;
    font-size: small;
  }

  .btn {
    margin-top: 15px;
    border-color: #707274;
    color: #707274;
    background-color: #9eb454a9;
    font-weight:bold;
    font-size: small;
  }

  .btn:hover {
    border-color: #9eb454;
    color: #9eb454;
    background-color: #707274;
    font-weight: bold;
  }
}
  
/* ======================= FOR LARGE DEVICES  ======================= */
  @media screen and (min-width:844px) and (max-width:1920px) {
    .machine-container {
      margin: 0;
      margin-top: 20px;
      padding: 8px;
      max-width: 100%;
    }

    .blowinFilm {
      display: flex;
      margin: 0;
      padding: 5px;
    }

    #intro {
      margin: auto;
    }

    #introR {
      margin: auto;
      margin-right: 0;
    }

    .h2Head {
      margin: 0;
    }

    .devider {
      margin: auto;
      margin-top: 50px;
      margin-bottom: 20px;
    }

    .rHead,
    .introHead {
      margin: 0;
      font-size: large;
      margin-top: 30px;
      padding: 5px;
      font-weight: bold;
    }

    .pIntro,
    .rIntro {
      margin: 0;
      padding: 5px;
      font-size: medium;
      line-height: 1.5;
      color: #707274;
    }

    .card {
      margin: 3px;
    }

    .card-title {
      padding: 5px;
      margin-bottom: 8px;
      font-size: medium;
      font-weight: bold;
    }

    #textPr,
    #textGh,
    #textCr {
      padding: 5px;
      margin-bottom: 8px;
      font-size: small;
      font-weight: 500;
    }

    #btnGH,
    #btnCrr,
    #btnPR {
      margin: 0;
      font-size: x-small;
    }
  }