/* ==================== Goals ====================*/
.about {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-image: url(../../assets/bgLogo.png);
  background-repeat: no-repeat;
  background-size: 65%;
  background-position:calc(50% + 1.2rem)
}

#colId1 {
  max-width: 100%;
  margin: 0;
  padding: 10px
}

#colId {
  max-width: 100%;
  margin: 0;
  padding: 10px;
  margin-top: 25px;
}

.colGoal {
  margin-top: 140px;
}

#rowId {
  max-width: 100%;
  margin: 0;
  padding-right: 8px;
  padding-left: 8px;
  
}


.fa-bullseye,
.fa-rocket,
.fa-eye,
.fa-hand-holding-heart {
  max-width: fit-content;
  padding: 10px;
  display: block;
  text-align: center;
  color:#9eb454;
  margin:auto;
}

.rowHead{
  display: block;
  overflow-wrap: break-word;
  text-align: center;
  color:#9eb454;
  max-width: 100%;
  margin: 0;
  padding: 8px;
  font-size: x-large;
  font-weight: bold;
}

#goals-ul {
  display: block;
  max-width: 80%;
  margin: 0 auto;
  padding: 8px;
  text-align: center;
  font-weight: 400;
  font-size: medium;
  color:#707274;
  line-height: 1.2;
}

#about-ul {
  display: block;
  max-width: 80%;
  margin: 0 auto;
  padding: 8px;
  text-align: left;
  font-weight: 400;
  font-size: medium;
  color:#707274;
  line-height: 1;
}

.aboutLi {
  list-style-type: none;
  padding: 10px;
  line-height: 1.1;
}

.aboutP {
  display: block;
  max-width: 80%;
  margin: 0 auto;
  padding: 8px;
  text-align: left;
  font-weight: 400;
  font-size: medium;
  color:#707274;
  line-height: 1.8;
}

/* ==================== FOR SMALL DEVICES ==================== */
@media screen and (min-width: 320px) and (max-width: 844px) {
  #colId1 {
    margin-top: 120px;
  }
}

/* ======================= FOR LARGE DEVICES  ======================= */

  @media screen and (min-width:844px) and (max-width:1920px) {
  #colId1 {
    margin-top: 30px;
  }

  .rowHead {
    font-size: larger;
    font-weight: 400;
    margin-top: 8px;
  }
}

























/* ==================== FOR SMALL DEVICES ==================== */

@media screen and (max-width:768px) {
  #colId,
  #rowId {
    margin: 5px;
  }

  .aboutP,
  #about-ul,
  #goals-ul {
    max-width: 100%;
    text-align: left;
    margin: 5px;
  }

  .aboutLi {
  list-style-type:square;
}
}