.Products{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-image: url(../../assets/bgLogo.png);
  background-repeat: no-repeat;
  background-size: cover;
}

#Rolls {
  margin: auto;
  margin-top: 50px;
  padding: 0;
  max-width: 80%;
}

.mainRow {
  margin: 0;
  padding:0;
}

#pCol {
  display: block;
  margin: 0;
  padding: 0;
  max-width: fit-content;
}

.pRow {
  display: flex;
  margin: 0;
  padding: 0;
}

#RollImg {
  margin: 0;
  padding: 5px;
  width: 300px;
  height: 200px;
}

.bodyCol {
  margin: 0;
  padding: 0;
}

.card-body {
  margin: 0;
  padding: 0;
}

.card-title {
  margin: 0;
}

.card-text {
  margin: 0;
  padding: 5px;
  line-height: 1.1;
  font-size: smaller;
}

.h6 {
  margin: 0;
  padding: 1px;
}

.ulTrans {
  margin: 0;
  padding: 10;
}

#btnRoll{
  float: right;
  margin-right: 5px;
  margin-top: 10px;
}

/* ======================= FOR SMALL DEVICES  ======================= */

@media screen and (min-width: 320px) and (max-width: 844px) {
  .Products {
    margin: 0;
    margin-top: 150px;
    padding: 0;
  }

  .pRow {
    width: auto;
  }

  #RollImg {
    width: auto;
  }

  .ulProducts,
  .ulSize,
  .ulColor,
  .ulTrans {
    font-size: smaller;
  }
}

/* ======================= FOR LARGE DEVICES  ======================= */
  @media screen and (min-width:844px) and (max-width:1920px) {

  .ulProducts,
  .ulSize,
  .ulColor,
  .ulTrans {
    font-size: larger;
  }
  #btnRoll{
    float: right;
    margin-right: 5px;
    margin-top: 20px
  }

  .fadeLeft .ulProducts,
  .fadeUp .ulProducts {
    line-height: 1.5;
  }

  }